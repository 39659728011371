// import Swal from 'sweetalert2';

// export const onServiceWorkerUpdateReady = () => {
//   //   const answer = window.confirm(
//   //     `This application has been updated. ` +
//   //       `Reload to display the latest version?`,
//   //   );
//   Swal.fire({
//     position: 'top-end',
//     title: 'Página actualizada',
//     showConfirmButton: false,
//     timer: 1500,
//   });
//   setTimeout(() => {
//     window.location.reload();
//   }, 1500);
// };

export const registerServiceWorker = () => true;
